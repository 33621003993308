@import "font.css";

body {
  margin: 0;
  font-family: "myfont", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:  #ffe58f;
}

code {
  font-family: "myfont", source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  background-color:  #ffe58f;
}

@layout-body-background: #ffe58f;@body-background: #ffe58f;@border-radius-base: 6px;@font-size-base: 15px;@primary-color: #874d00;@component-background: #ffe58f;