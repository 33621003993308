@font-face {
    font-family: 'myfont';
    src:url("./res/english.ttf");
}
@font-face {
    font-family: 'myfontbold';
    src:url("./res/english_bold.ttf");
}
@font-face {
    font-family: 'myfontNum';
    src:url("./res/num.ttf");
}
@layout-body-background: #ffe58f;@body-background: #ffe58f;@border-radius-base: 6px;@font-size-base: 15px;@primary-color: #874d00;@component-background: #ffe58f;