@import '~antd/dist/antd.less';
@import "font.css";
.App {
  text-align: center;
}
.Radio{

  color: white !important;
}
.headimg{
  //position: absolute;
  z-index: 1;
  //left: 0px;
  //top:0px;
}
.avatarpic{
  border-radius:50%;
}
.platformimg{
  position: absolute;
  z-index: 3;
  left: 35px;
  top:32px;
}
.vipimg{
  position: absolute;
  z-index:4;
  left: 23px;
  top:4px;
}
.vipheadimg{
  position: absolute;
  z-index: 2;
}
.rulediv{
  width: 550px;
  color: white;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.name{
  height: 30px;
  font-family: 'myfont';
  margin-left: 20px;
  font-size: 20px;
  color: #874d00;
}
.nameandpic{
  display: flex;
  align-items: center;
}
.tag{
  width: 120px;
  height: 25px;
  font-size: 20px;
  background-color: #ffe58f;
  text-align: center;
  color: #874d00;
}
.ant-table-column-sort{
  background-color: #ffe58f !important;
}
.main{

}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.ant-table {
  min-height: 650px !important;
}

.ant-menu-item-selected {
  background-color: #874d00 !important;
  color: white !important;
}
.ant-menu-item {
  border-bottom:2px solid #ad6800;
}
.img{
  height: 25px;
  margin-left: 20px;
}
.span {
  height: 25px;
  font-size: 20px;
  margin-left: 24px;
  color: #874d00;
}
.headerspan{
  font-family: 'myfontbold';
  margin-bottom: 10px;
}
.divsider{
  font-family: 'myfont';
  background-color: #ffe58f
}
.num{
  font-family: 'myfontNum';
}
.headerdiv{
  background-image: url("./res/bg_main_title.png");
  height:94px;
  width:589px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size:100% 100%;
  font-size: calc(20px + 2vmin);
  color: #ad6800;
}
.ant-table-thead > tr >th{

  color: white;

  background: #ad6800 !important;

}

.App-header {
  font-family: 'myfont';
  background-color:  #ffe58f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


}
.light-row{
  border-radius:20px !important;
  background-color: #fffbe6;
}
.dark-row{
  background-color: #fffbe6;
}
.menudiv{
  border-bottom:1px solid #ad6800;
}
.App-link {
  color: #61dafb;
}
.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right: 0px;
}
.Menu{
  background-color:  #ffe58f;
  text-align:center;
}
@keyframes Home-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@layout-body-background: #ffe58f;@body-background: #ffe58f;@border-radius-base: 6px;@font-size-base: 15px;@primary-color: #874d00;@component-background: #ffe58f;